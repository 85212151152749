import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './AdminSidebar';
import Cookies from 'js-cookie';

function Dashboard() {
  const navigate = useNavigate();
  const [role, setRole] = useState('admin');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem('chatSidebarOpen', JSON.stringify(!sidebarOpen));
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem('chatSidebarOpen');
    if (savedSidebarState !== null) {
      setSidebarOpen(JSON.parse(savedSidebarState));
    }
  }, []);

  return (
    <div className="flex h-screen overflow-hidden font-sans bg-gray-50">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        handleLogout={handleLogout}
        role={role}
      />
      <div className={`flex-grow p-6 transition-all duration-300 ${sidebarOpen ? 'ml-64' : 'ml-20'} overflow-auto`}>
        <h2 className="text-2xl font-bold text-gray-600 mb-4 text-center">Dashboard Admin</h2>
        <div className="space-y-4 flex flex-col items-center">
          <div
            onClick={() => handleNavigate('/admin/folders')}
            className="relative bg-gray-100 p-4 rounded-lg shadow-lg cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300 max-w-sm w-full"
          >
            <h3 className="text-lg font-bold text-gray-700 mb-2">Gestion des collections et documents</h3>
            <p className="text-gray-600">Gérer les collections et les documents</p>
          </div>
          <div
            onClick={() => handleNavigate('/admin/users')}
            className="relative bg-gray-100 p-4 rounded-lg shadow-lg cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300 max-w-sm w-full"
          >
            <h3 className="text-lg font-bold text-gray-700 mb-2">Gestion des utilisateurs</h3>
            <p className="text-gray-600">Gérer les utilisateurs</p>
          </div>
          <div
            onClick={() => handleNavigate('/admin/glossaries')}
            className="relative bg-gray-100 p-4 rounded-lg shadow-lg cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300 max-w-sm w-full"
          >
            <h3 className="text-lg font-bold text-gray-700 mb-2">Gestion des glossaires</h3>
            <p className="text-gray-600">Gérer les glossaires et les termes</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
