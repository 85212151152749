import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Chat from './components/Chat';
import Home from './components/Home';
import Login from './components/Login';
import Admin from './components/Admin'; // Import the Admin component
import Dashboard from './components/Dashboard';
import FolderManagement from './components/FolderManagement';
import UserManagement from './components/UserManagement';
import GlossaryManagement from './components/GlossaryManagement';
import GlossaryDetail from './components/GlossaryDetail';
import GlossaryList from './components/GlossaryList'; // Import the GlossaryList component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/admin/folders" element={<FolderManagement />} />
        <Route path="/admin/users" element={<UserManagement />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/:folderId" element={<FolderManagement />} />
        <Route path="/admin/:folderId/glossaries" element={<GlossaryList />} /> {/* Add this route */}
        <Route path="/admin/glossaries" element={<GlossaryManagement />} />
        <Route path="/admin/glossaries/:glossaryId" element={<GlossaryDetail />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
